import { useCallback, useEffect, useState } from 'react'
import { rect } from 'martha'
import { useIsFontsLoaded } from 'context/globalStore'

export default function useScrollIntoView(options = {}) {
  const { isFontsLoaded } = useIsFontsLoaded()
  const [target, setTarget] = useState()
  const callbackRef = useCallback(setTarget)

  useEffect(() => {
    if (target && isFontsLoaded) {
      const bounds = rect(target)
      const isFullyVisible = bounds.left >= 0 && bounds.left + bounds.width < window.innerWidth

      if (!isFullyVisible) {
        requestAnimationFrame(() => {
          target.scrollIntoView(options)
        })
      }
    }
  }, [isFontsLoaded, target])

  return callbackRef
}
