import cx from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import * as analytics from 'lib/analytics';
import { screens } from 'lib/constants';
import { useMedia } from 'use-media';

export default function AnchorNavigation({ menu }) {
  const router = useRouter();
  const [activeHash, setActiveHash] = useState('');
  const isScreenLarge = useMedia({ minWidth: screens.l });
  const offset = isScreenLarge ? 50 : 100;
  const menuRefs = menu.reduce((acc, value) => {
    acc[value.reference.hash] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    const menuElement = document.querySelector('.sticky-nav');
    if (activeHash && menuRefs[activeHash] && menuRefs[activeHash].current) {
      const rect = menuRefs[activeHash].current.getBoundingClientRect();
      const isInViewPort = rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      if (menuElement?.getBoundingClientRect().top <= window.innerHeight && !isInViewPort) {
        setTimeout(() => {
          menuRefs[activeHash]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          });
        }, 100);
      }
    }
  }, [activeHash, menuRefs]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 4;

      for (const item of menu) {
        const sectionElement = document.getElementById(item.reference.hash);
        if (sectionElement) {
          const offsetTop = sectionElement.offsetTop - offset;
          const offsetBottom = offsetTop + sectionElement.offsetHeight;
          if (scrollPosition >= offsetTop && scrollPosition <= offsetBottom) {
            setActiveHash(item.reference.hash);
          }
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menu, offset, activeHash, menuRefs]);

  const scrollToSection = (e, hash) => {
    e.preventDefault();
    const url = `${router.asPath.split('#')[0]}#${hash}`;
    window.history.pushState({}, '', url);
    const sectionElement = document.getElementById(hash);
    if (sectionElement) {
      const offsetTop = sectionElement.offsetTop - offset
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
      setTimeout(() => {
        setActiveHash(hash);
      }, 100);
    }
  }

  useEffect(() => {
    const handleRouteChange = () => {
      const [, hash] = window.location.href.split('#');
      setActiveHash(hash || '');
    };

    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <nav className="sticky-nav bg-alice-blue top-55 l:top-65 h-50 w-full shadow-top-bar z-30 text-16 hide-scrollbar overflow-x-auto text-center">
      <div className="inline-block h-full px-15 l:px-30 xl:px-40 l:w-full l:max-w-1000">
        <ul className="flex h-full m:space-x-15 l:space-x-30 whitespace-nowrap -mx-10 l:justify-around">
          {menu.map((item) => (
            <li key={item.title} ref={menuRefs[item.reference.hash]}>
              <a
                href={`#${item.reference.hash}`}
                className={cx(
                  'text-16 h-full flex items-center px-10 relative',
                  { 'font-medium': activeHash === item.reference.hash }
                )}
                onClick={(e) => {
                  scrollToSection(e, item.reference.hash);
                  analytics.trackScrollClick(item.title);
                }}
              >
                <span style={{ color: activeHash === item.reference.hash ? '#003057' : '#4D6E89' }}>
                  {item.title}
                </span>
                <a
                  style={{ backgroundColor: activeHash === item.reference.hash ? '#8BBCFF' : '#4D6E89' }}
                  className={cx('absolute inset-x-7 bottom-0 h-4', {
                    'opacity-0': activeHash !== item.reference.hash,
                  })}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}


