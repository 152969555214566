import { useRef } from 'react'
import Link from 'components/Link'
import cx from 'classnames'
import { useRouter } from 'next/router'
import useScrollIntoView from 'hooks/useScrollIntoView'
import * as analytics from 'lib/analytics'

export default function CollectionNavigation({ menu }) {
  const router = useRouter()
  const scroller = useRef(null)
  const activeRef = useScrollIntoView({ inline: 'start' })

  const menus = menu.leftMenu.filter(item => item.collection.title !== 'Shop All')
  const rightMenus = menu.rightMenu ? menu.rightMenu : []

  return (
    <nav className="sticky top-0 bg-alice-blue h-50 shadow-top-bar z-9 pt-12 pb-12 text-0 text-center">
      <div
        ref={scroller}
        className="inline-block h-full px-15 m:px-30 xl:px-40 w-full s:w-auto max-w-full hide-scrollbar overflow-x-auto"
      >
        <ul
          className={cx('flex h-full m:space-x-15 l:space-x-30 -mx-10 whitespace-nowrap w-full items-center justify-start')}
        >
          {menus.map(({ collection }, index) => {
            const link = collection
            const isActive = link.reference.slug === router.query.slug
            return (
              <li key={link._id} ref={isActive ? activeRef : null}>
                <Link
                  link={link}
                  className={cx(
                     'text-13 l:text-14 leading-17 l:leading-17.5 font-medium h-full flex items-center px-10 relative'
                  )}
                  onClick={() => analytics.trackScrollClick(link.title)}
                >
                  <span
                    aria-hidden="true"
                    className={cx('absolute-center', {
                      'opacity-0': isActive,
                    })}
                  >
                    {link.title}
                  </span>
                  <span
                    className={cx('font-medium', {
                      'opacity-0': !isActive,
                    })}
                  >
                    {link.title}
                  </span>
                  <span
                    className={cx('absolute inset-x-7 bottom-0 h-4 bg-malibu-blue', {
                      'opacity-0': !isActive,
                    })}
                  ></span>
                </Link>
              </li>
            )
          })}
          {
            rightMenus.length > 0 &&
            rightMenus.map(({ collection }, index) => {
              const link = collection
              const isActive = link.reference.slug === router.query.slug
              return (
                <li
                  key={link._id}
                  className={cx({
                    'border-l border-sky-blue pl-10 ml-10 l:ml-0 l:pl-30': index === 0,
                  })}
                  ref={isActive ? activeRef : null}
                >
                  <Link
                    link={link}
                    className={cx(
                      'text-13 l:text-14 leading-17 l:leading-17.5 font-medium h-full flex items-center px-10 relative'
                    )}
                    onClick={() => analytics.trackScrollClick(link.title)}
                  >
                    <span
                      aria-hidden="true"
                      className={cx('absolute-center', {
                        'opacity-0': isActive,
                      })}
                    >
                      {link.title}
                    </span>
                    <span
                      className={cx('font-medium', {
                        'opacity-0': !isActive,
                      })}
                    >
                      {link.title}
                    </span>
                    <span
                      className={cx('absolute inset-x-7 bottom-0 h-4 bg-malibu-blue', {
                        'opacity-0': !isActive,
                      })}
                    ></span>
                  </Link>
                </li>
              )
            })}
        </ul>
      </div>
    </nav>
  )
}
