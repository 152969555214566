import Layout from 'components/Layout'
import AnchorNavigation from 'components/AnchorNavigation'
import StickyCta from 'components/StickyCta'
import GlobalModules from 'components/GlobalModules'
import BifurcatedNav from 'components/Navigation/BifurcatedNav'
import BifurcatedNavOld from 'components/BifurcatedNavOld'
import { buildPageUTMValues } from 'lib/buildPageUTMValues'
import Script from 'next/script'

export default function Page(context) {
  if (!context) return
  const { page: contextPage, footer, globals, homepageCta, canonical, plps, navigation } = context
  const settings = {
    allReviews: 'allReviews',
    reviewCarousel: 'reviewCarousel'
  }
  const page = buildPageUTMValues(contextPage)
  const { enableMobileHomepageCta: mcta } = globals
  const mobileCta = typeof mcta === 'boolean' ? mcta : true
  const hasAllReviews = page?.modules?.filter((module) => module._type === settings.allReviews || module._type === settings.reviewCarousel )

  return (
    <>
      {hasAllReviews && (
        <>
          <meta name="oke:subscriber_id" content="e6d9f49e-7e6c-4065-9eab-110a3e81ece4" />
          <Script
            async={true}
            defer={true}
            src="https://cdn-static.okendo.io/reviews-widget-plus/js/okendo-reviews.js"
          />
        </>
      )}
      <Layout
        globals={globals}
        footer={footer}
        canonical={canonical}
        metaTitle={canonical !== '/' ? page?.metaTitle ?? `${page.title} – Blueland` : undefined}
        metaDescription={page?.metaDescription}
        openGraphTitle={page?.openGraphTitle}
        openGraphDescription={page?.openGraphDescription}
        openGraphImage={page?.openGraphImage}
        twitterTitle={page?.twitterTitle}
        twitterDescription={page?.twitterDescription}
        twitterImage={page?.twitterImage}
        allowIndexing={page?.allowIndexing}
      >
        {page?.overrideMainHeader && <h1 className="sr-only">{page.metaTitle}</h1>}
        {!contextPage?.isNoNavigation && (
          <div className="primary-categories">
            {navigation.isNewNavigation ? (
              <BifurcatedNav
                menu={{ leftMenu: navigation.leftMenuItems, rightMenu: navigation.rightMenuItems }}
                isNoNavigation={contextPage?.isNoNavigation}
              />
            ) : (
              <BifurcatedNavOld
                menu={{ leftMenu: navigation.leftMenuItems, rightMenu: navigation.rightMenuItems }}
                isNoNavigation={contextPage?.isNoNavigation}
              />
            )}
          </div>
        )}
        {homepageCta && mobileCta ? (
          <StickyCta link={homepageCta}>{homepageCta.title}</StickyCta>
        ) : null}
        {page?.modules ? (
          <GlobalModules modules={page.modules} pageContext={context} />
        ) : (
          <div className="h-screen--nav-and-promo p-15">{page.title}</div>
        )}
      </Layout>
    </>
  )
}
