import Button from 'components/Button'

export default function StickyCta({ children, link }) {
  return (
    <div className="l:hidden fixed bottom-0 inset-x-0 bg-gradient-to-t from-pattens-blue via-pattens-blue to-transparent-pattens-blue p-15 z-3 pointer-events-none">
      <Button
        className="btn--mykit-atc pointer-events-auto w-full text-22 text-center"
        link={link}
        variant="contained"
        size="large"
      >
        {children}
      </Button>
    </div>
  )
}
